<template>
  <threshold-management :datas="[5, 'editBiaoMianParam']"></threshold-management>
</template>
<script>
import ThresholdManagement from '@/components/threshold/ThresholdManagement.vue'
export default {
  components: {
    ThresholdManagement,
  },
  data() {
    return {}
  },
}
</script>
